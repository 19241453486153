import React, { useEffect, useState } from "react";
import FooterContainer from "../Footer/FooterContainer/footerContainer";
import Header from "../Header/Header";
import BackgroundImage from "../assets/background01.jpeg";
import "./Page.css";
import { levels, rooms } from "./components/RoomMatrix";
import TextForm from "./components/TextForm/textForm";
import SlotsMap from "./components/SlotsMap/slotsMap";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import { buttonStyle } from "./Page.jss";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { getAllSlots, getHistoryRecords, postData } from "../api";
import { slots } from "./components/SlotsMap/slotsMatrix";
import { IHistoryData, IRequestData, ISlotsData } from "../UI/Interfaces";
import DropdownSelect from "./components/DropdownSelect/dropdownSelect";
import Summary from "./components/Summary/summary";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";

const Page = ({
  slotsData,
  historyData,
  changedData,
  setAllSlotsData,
  setHistoryData,
}: {
  slotsData: ISlotsData[];
  historyData: IHistoryData[];
  changedData: (value: boolean) => void;
  setAllSlotsData: (value: ISlotsData[]) => void;
  setHistoryData: (value: IHistoryData[]) => void;
}) => {
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [plateNumber, setPlateNumber] = useState("");
  const [startTime, setStartTime] = useState(dayjs(new Date()));
  const [endTime, setEndTime] = useState(dayjs(startTime).add(24, "hour"));
  const [endTimeCheck, setEndTimeCheck] = useState(
    dayjs(startTime).add(24, "hour")
  );
  const [levelRooms, setLevelRooms] = useState([]);
  const [showSummary, setShowSummary] = useState(false);
  const [updatedSlots, setUpdatedSlots] = useState(slots);
  const [slot, setSlot] = useState(slots[0]);
  const [occupiedSlots, setOccupiedSlots] = useState([]); //MARK: Occupied slots

  const createLevelRooms = (level: string) => {
    return rooms.map((room) => ({
      value: `${level}${room.value}`,
      label: `${level}${room.label}`,
    }));
  };

  //final object composition
  let requestData = {
    selectedSlot: slot?.value ?? "1",
    name: name,
    surname: surname,
    plateNumber: plateNumber,
    room: selectedRoom,
    startTime: startTime.format("DD.MM.YYYY HH:MM"),
    endTime: endTime.format("DD.MM.YYYY HH:MM"),
  };

  useEffect(() => {
    if (selectedLevel !== null) {
      //@ts-ignore
      setLevelRooms(createLevelRooms(selectedLevel));
      //@ts-ignore
      setSelectedRoom(createLevelRooms(selectedLevel)[0]["value"]);
    }
  }, [selectedLevel]);

  const onCheckSubmition = (reqData: IRequestData | null) => {
    if (reqData === null) {
      return;
    }
    const display = displaySummary();
    setShowSummary(display);
  };
  const fetchData = async () => {
    try {
      const slotsData = await getAllSlots();
      setAllSlotsData(slotsData);

      const historyData = await getHistoryRecords();
      setHistoryData(historyData);
    } catch (error) {
      console.error("Error fetching data during initialization phase: ", error);
    }
  };

  const onSubmit = async () => {
    const postDataResponse = await postData({
      ID: slot.value,
      full_name: name + " " + surname,
      room_number: selectedRoom,
      plate_number: plateNumber,
      time_from: startTime.format("YYYY-MM-DDTHH:mm:ss"),
      time_to: endTime.format("YYYY-MM-DDTHH:mm:ss"),
    });

    fetchData();
    alert("Rezervace byla vytvořena");

    if (postDataResponse.status === 200) {
      // changedData(true);
      // fetchData();
    }
  };

  const displaySummary = () => {
    return requestData.name !== "" &&
      requestData.surname !== "" &&
      requestData.plateNumber !== "" &&
      requestData.room !== null &&
      requestData.startTime !== null &&
      requestData.endTime !== null &&
      requestData.selectedSlot !== null
      ? true
      : false;
  };

  useEffect(() => {
    setEndTime(dayjs(startTime).add(24, "hour"));
  }, [startTime]);

  const filterSlots = (
    slotsData: ISlotsData[],
    historyData: IHistoryData[]
  ) => {
    const occupiedData: string[] = [];
    const freeData: string[] = [];

    slotsData.forEach((slot) => {
      const matchingHistoryRecords = historyData.filter(
        (record) => record.slot === String(slot.ID) // Convert ID to string
      );

      if (matchingHistoryRecords.length > 0) {
        const lastRecord =
          matchingHistoryRecords[matchingHistoryRecords.length - 1];
        const currentTime = dayjs();
        const timeFrom = dayjs(lastRecord.time_from);
        const timeTo = dayjs(lastRecord.time_to);
        if (currentTime.isAfter(timeFrom) && currentTime.isBefore(timeTo)) {
          occupiedData.push(slot.ID.toString());
        } else {
          freeData.push(slot.ID.toString());
        }
      } else {
        freeData.push(slot.ID.toString());
      }
    });
    return { occupiedData, freeData };
  };

  useEffect(() => {
    const result = filterSlots(slotsData, historyData); //MARK: Filtering slots
    const filteredSlots = updatedSlots.filter(
      (slot) => !result.occupiedData.includes(slot.value)
    );
    setUpdatedSlots(filteredSlots);

    setOccupiedSlots(result.occupiedData as never[]);
  }, [historyData, slotsData]);

  return (
    <>
      <div className="main-wrapper">
        <Header />
        <div className="content-wrapper">
          <img
            src={BackgroundImage}
            alt="hotel-backgroud"
            className="image-background" //MARK: Background image
          />
          <div>
            <div className="content-container">
              <TextForm
                title={"Jméno"}
                onChange={(value) => {
                  setName(value);
                }}
              />
              <TextForm
                title={"Příjmení"}
                onChange={(value) => {
                  setSurname(value);
                }}
              />
              <DropdownSelect
                title={"Patro pokoje"}
                intialValue={selectedLevel}
                onChange={function (value: any): void {
                  setSelectedLevel(value.value);
                }}
                options={levels}
                className="selectElevation"
              />
              {selectedLevel !== null && (
                <DropdownSelect
                  title={"Pokoj"}
                  intialValue={selectedRoom}
                  onChange={function (value: any): void {
                    setSelectedRoom(value.value);
                  }}
                  options={levelRooms}
                  className="selectElevation"
                />
              )}
              <TextForm
                title={"SPZ"}
                onChange={(value) => {
                  setPlateNumber(value);
                }}
                useUpperCase={true}
              />
              <DropdownSelect
                title={"Parkovací místo"}
                intialValue={updatedSlots[0]}
                onChange={function (value: any): void {
                  setSlot(value);
                }}
                options={updatedSlots}
                className="selectElevation"
              />
              <div className="time-input-style">
                <DateTimePicker
                  label="Začátek rezervace"
                  value={dayjs(startTime) ?? new Date()}
                  onChange={
                    (newValue: Dayjs | null) =>
                      setStartTime(dayjs(newValue) ?? null)
                    //MARK: Datetime pickers
                  }
                  format="DD/MM/YYYY HH:mm"
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  disablePast
                />
              </div>
              <div className="time-input-style">
                <DateTimePicker
                  label="Konec rezervace"
                  value={dayjs(endTime) ?? new Date()}
                  onChange={(newValue: Dayjs | null) =>
                    setEndTime(dayjs(newValue) ?? null)
                  }
                  format="DD/MM/YYYY HH:mm"
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  minDate={dayjs(endTimeCheck)}
                  minTime={dayjs(endTimeCheck)}
                />
              </div>
              <div className="input-style">
                <button
                  onClick={() => onCheckSubmition(requestData)}
                  style={buttonStyle}
                >
                  <p style={{ color: "white" }}>Zkontrolovat rezervaci</p>
                </button>
              </div>
            </div>
            <div className="form-results">
              <div className="slotMap">
                <SlotsMap occupancy={occupiedSlots} />
              </div>
              {showSummary && (
                <div className="summary-container">
                  <Summary requestData={requestData} onSubmit={onSubmit} />
                </div>
              )}
            </div>
          </div>
        </div>
        <FooterContainer />
      </div>
    </>
  );
};

export default Page;
