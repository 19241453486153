import React, { CSSProperties, useState, useEffect } from "react";
import MapImage from "../../../assets/Asset_5.png";
import { slotsCoordinates } from "./slotsMatrix";

interface Slot {
  slot: string;
}

interface SlotsMapProps {
  occupancy: Slot[];
}

const SlotsMap: React.FC<SlotsMapProps> = ({ occupancy }) => {
  const [coloredTiles, setColoredTiles] = useState<
    { coordinate: string; color: string }[]
  >([]);

  const mapStyle: CSSProperties = {
    width: "80%",
    height: "auto",
    backgroundColor: "white",
    marginTop: "20px",
    marginLeft: "5%",
    marginBottom: "80px",
    position: "relative",
  };

  const gridLineStyle: CSSProperties = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  };

  useEffect(() => {
    const avaliabilityColors = {
      available: "rgba(144, 238, 144, 0.5)",
      reserved: "rgba(255, 165, 0, 0.5)",
      occupied: "rgba(255, 0, 0, 0.5)",
    };

    setColoredTiles(
      slotsCoordinates.map((slot) => {
        const foundOccupancy = occupancy.find(
          //@ts-ignore
          (item) => item === slot.slot
        );
        const color = foundOccupancy
          ? avaliabilityColors.occupied
          : avaliabilityColors.available;
        return { coordinate: slot.position, color };
      })
    );
  }, [occupancy]);

  const numRows = 6;
  const numColumns = 15;
  const gridWidth = "2px";
  const gridColor = "transparent";

  const rows = [];
  const rowHeight = 100 / numRows;

  for (let i = 0; i < numRows; i++) {
    const yCoordinate = `${i * rowHeight}%`; // y-coordinate
    rows.push(
      <line
        key={`row-${i}`}
        x1="0"
        y1={yCoordinate}
        x2="100%"
        y2={yCoordinate}
        stroke={gridColor}
        strokeWidth={gridWidth}
      />
    );
  }

  // Generate columns
  const columns = [];
  const columnWidth = 100 / numColumns;

  for (let i = 0; i < numColumns; i++) {
    const xCoordinate = `${i * columnWidth}%`; // x-coordinates
    columns.push(
      <line
        key={`column-${i}`}
        x1={xCoordinate}
        y1="0"
        x2={xCoordinate}
        y2="100%"
        stroke={gridColor}
        strokeWidth={gridWidth}
      />
    );
  }

  // Generate tile rectangles
  const tileRectangles = coloredTiles.map(({ coordinate, color }) => {
    const row = coordinate.charCodeAt(0) - 65; //letter to row index
    const column = parseInt(coordinate.substring(1)) - 1; //number to column index
    const tileWidth = `${columnWidth}%`;
    const tileHeight = `${rowHeight}%`;
    const x = `${column * columnWidth}%`;
    const y = `${row * rowHeight}%`;

    return (
      <rect
        key={`tile-${coordinate}`}
        x={x}
        y={y}
        width={tileWidth}
        height={tileHeight}
        fill={color}
        stroke="black"
        strokeWidth="1"
      />
    );
  });

  return (
    <div style={mapStyle}>
      <img src={MapImage} alt="hotel-parking" style={{ width: "100%" }} />
      <svg style={gridLineStyle}>
        {tileRectangles}
        {rows}
        {columns}
      </svg>
    </div>
  );
};

export default SlotsMap;
