import React from "react";
import "./footerContainer.css";

const FooterContainer: React.FC = () => {
  return (
    <div className="foundation-footer">{/* Footer content goes here */}</div>
  );
};

export default FooterContainer;
