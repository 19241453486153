import { useEffect, useState } from "react";
import "./App.css";
import { getAllSlots, getHistoryRecords } from "./api";
import Page from "./PageContent/Page";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { IHistoryData, ISlotsData } from "./UI/Interfaces";

function App() {
  const [allSlotsData, setAllSlotsData] = useState<ISlotsData[]>([]);
  const [historyData, setHistoryData] = useState<IHistoryData[]>([]);
  const [changed, setChanged] = useState<boolean>(false);

  const fetchData = async () => {
    try {
      const slotsData = await getAllSlots();
      setAllSlotsData(slotsData);

      const historyData = await getHistoryRecords();
      setHistoryData(historyData);
    } catch (error) {
      console.error("Error fetching data during initialization phase: ", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    console.log("Changed data detected, fetching new data...", changed);
    fetchData();
    setChanged(false);
  }, [changed]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Page
        slotsData={allSlotsData}
        historyData={historyData}
        changedData={setChanged}
        setAllSlotsData={setAllSlotsData}
        setHistoryData={setHistoryData}
      />
    </LocalizationProvider>
  );
}

export default App;
