export const levels = [
  { value: "2", label: "2. patro" },
  { value: "3", label: "3. patro" },
  { value: "4", label: "4. patro" },
  { value: "5", label: "5. patro" },
  { value: "6", label: "6. patro" },
];

export const rooms = [
  { value: "01", label: "01" },
  { value: "02", label: "02" },
  { value: "03", label: "03" },
  { value: "04", label: "04" },
  { value: "05", label: "05" },
  { value: "06", label: "06" },
  { value: "07", label: "07" },
  { value: "08", label: "08" },
  { value: "09", label: "09" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
];
