import React, { useEffect, useState } from "react";
import { IRoom } from "../../../UI/Interfaces";
import { buttonStyle } from "../../Page.jss";
import "./summary.css";
interface SummaryProps {
  requestData: {
    name: string;
    surname: string;
    plateNumber: string;
    room: IRoom | null;
    selectedSlot: string;
    startTime: string;
    endTime: string;
  };
  onSubmit: () => void;
}

const Summary: React.FC<SummaryProps> = ({ requestData, onSubmit }) => {
  const [room, setRoom] = useState<IRoom | null>(null);
  useEffect(() => {
    if (requestData.room) {
      setRoom(requestData.room);
    }
  }, [requestData]);

  return (
    <>
      <div className="column-container">
        <div className="result-container">
          <div className="control">
            <p className="control-paragraph">Jméno:</p>
            <p className="control-paragraph">Příjmení:</p>
            <p className="control-paragraph">SPZ:</p>
            <p className="control-paragraph">Pokoj:</p>
            <p className="control-paragraph">Parkovací místo:</p>
            <p className="control-paragraph">Čas rezervace od:</p>
            <p className="control-paragraph">Čas rezervace do:</p>
          </div>
          <div className="data">
            <p className="data-paragraph">{requestData.name}</p>
            <p className="data-paragraph">{requestData.surname}</p>
            <p className="data-paragraph">{requestData.plateNumber}</p>
            <p className="data-paragraph">{String(room)}</p>
            <p className="data-paragraph">{requestData.selectedSlot}</p>
            <p className="data-paragraph">{requestData.startTime}</p>
            <p className="data-paragraph">{requestData.endTime}</p>
          </div>
        </div>
        <div className="submit-button">
          <button
            onClick={() => onSubmit()}
            style={{
              ...buttonStyle,
              width: "100%",
              justifyContent: "center",
              alignSelf: "center",
            }}
          >
            <p style={{ color: "white" }}>Vytvořit rezervaci</p>
          </button>
        </div>
      </div>
    </>
  );
};

export default Summary;
