export const slots = Array.from({ length: 31 }, (_, index) => ({
  value: String(index + 1),
  label: String(index + 1),
}));

export const slotsCoordinates = [
  { slot: "1", position: "A11" },
  { slot: "2", position: "A10" },
  { slot: "3", position: "A9" },
  { slot: "4", position: "A8" },
  { slot: "5", position: "A7" },
  { slot: "6", position: "A6" },
  { slot: "7", position: "A5" },
  { slot: "8", position: "A4" },
  { slot: "9", position: "C11" },
  { slot: "10", position: "C10" },
  { slot: "11", position: "C9" },
  { slot: "12", position: "C8" },
  { slot: "13", position: "C7" },
  { slot: "14", position: "C6" },
  { slot: "15", position: "C5" },
  { slot: "16", position: "D11" },
  { slot: "17", position: "D10" },
  { slot: "18", position: "D9" },
  { slot: "19", position: "D8" },
  { slot: "20", position: "D7" },
  { slot: "21", position: "D6" },
  { slot: "22", position: "D5" },
  { slot: "23", position: "D3" },
  { slot: "24", position: "F9" },
  { slot: "25", position: "F8" },
  { slot: "26", position: "F7" },
  { slot: "27", position: "F6" },
  { slot: "28", position: "F5" },
  { slot: "29", position: "F4" },
  { slot: "30", position: "F3" },
  { slot: "31", position: "F2" },
];
