import React from "react";
import Select from "react-select";
import "./dropdownSelect.css";

interface IDropdownSelectProps {
  title: string;
  intialValue: any;
  onChange: (value: any) => void;
  options: any;
  className?: string;
}

const DropdownSelect: React.FC<IDropdownSelectProps> = ({
  title,
  intialValue,
  onChange,
  options,
  className,
}) => {
  return (
    <>
      <p className="input-style">{title}</p>
      <Select
        defaultValue={intialValue}
        onChange={(value) => {
          onChange(value);
        }}
        options={options}
        className={className}
      />
    </>
  );
};

export default DropdownSelect;
