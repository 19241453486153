// import { useState } from "react";
import "./App.css";

// interface IData {
//   id: number;
//   name: string;
// }

const urlCore = "http://localhost:10001";

function Api() {
  // const [data, setData] = useState<IData[] | null>(null);
  // const [loading, setLoading] = useState<boolean>(true);
}

export default Api;

export const getAllSlots = async () => {
  try {
    const response = await fetch(urlCore + "/get-all-slots");
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.log("/get-all-slots: " + error);
  } finally {
  }
};

export const getHistoryRecords = async () => {
  try {
    const response = await fetch(urlCore + "/get-slot-history");
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.log("/get-slot-history: " + error);
  } finally {
  }
};

export const postData = async (data: any) => {
  try {
    const response = await fetch(urlCore + "/create-reservation", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.log("/create-reservation: " + error);
  } finally {
  }
};
